import React, { useRef } from "react";
import logo from './logo.svg';
import instagram from './instagram.svg'
import arrow from './down-arrow.svg'
import whatsapp from './whatsapp.svg'
import './App.css';
import background from './background.mp4';
import {BrowserView, MobileView} from 'react-device-detect';

function App() {

  const pricing = useRef(null);

  const goToPricing = () => {
    pricing.current?.scrollIntoView({ block: "nearest", behavior: 'smooth' });
  }

  return (
    <>
    <div className="App">
      <div className="content">
        <div className='content-main'>
          <img src={logo} className="App-logo" alt="logo" />
          <p className="text">Serviço de acompanhamento físico personalizado online.<br/>
            Atreves-te a sonhar com a tua melhor versão? Conta comigo! <br/>
            Consulta o meu Instagram e encontra conteúdo que te vai ajudar a melhorar a qualidade dos teus treinos!</p>
          <div className='social-items'>
            <a className="social-item" href='https://www.instagram.com/thebodylaab/'>
              <img src={instagram} alt="instagram" />
            </a>
            <a className="social-item" href='https://wa.me/916808806'>
              <img src={whatsapp} alt="whatsapp" />
            </a>
          </div>
        </div>
        <div className='Moreinfo'>
          <p className="moreinfotext">Mais informações</p>
          <div className="arrow">
            <img src={arrow} alt="moreinfo" onClick={goToPricing} />
          </div>
        </div>
      </div>
      <div className="video-overlay"/>
        <BrowserView>
          <div className="video-background">
            <div className="video-foreground">
              <video autoPlay loop muted>
                <source src={background} type="video/mp4" />
              </video>
            </div>
          </div>
        </BrowserView>
        <MobileView>
          <div className='img-background'></div>
        </MobileView>
    </div>
    <div ref={pricing} className='Pricing'>
      <h2 className='pricing-title'>SERVIÇOS</h2>
      <stripe-pricing-table pricing-table-id="prctbl_1NMfdnPLKAn1KKnIYWL7GAbC"
      publishable-key="pk_live_51NMf9uPLKAn1KKnIYjgXcrS9E9smeuw5ezYyhKx0ESizCxIfhZTuVspFMDNlsgxKv4mtHItQW7G1Jn5bXXZqL9dn00HMaUYojl">
      </stripe-pricing-table>
    </div>
    <div className="footer">
      {/* <p>© 2023 The Body Lab . All Rights Reserved.</p> */}
    </div>
    </>
  );
}

export default App;
